.info_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.input {
    margin-top: 10px;
    width: 320px;
    height: 30px;
    font-size: 16px;
    background-color: transparent;
    border: 2px solid black;
    padding: 7px;
    border-radius: 20px;
}

.button {
    margin-top: 10px;
    padding: 10px 5px;
    border-radius: 15px;
    font-size: 16px;
    background-color: transparent;
    border: 2px solid black;
    box-shadow: 2px 2px 5px 2px rgba(170, 170, 170, 0.5);
}

.order_history {
    margin-top: 30px;
}

.title {
    margin-bottom: 20px;
}

.link {
    display: block;
    text-decoration: none;
    color: black;
}

.link + .link {
    margin-top: 10px;
}

