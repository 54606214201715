.montserrat {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}


*, *::after, *::before {
    margin: 0;
    padding: 0;
}

body {
    background-color: #F3F7FF;
}

.App {
    margin: 0 auto;
    width: 1100px;
    color: #08092B;
}

.wrapper {
    max-width: 1536px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

}

@media screen and (max-width: 1536px) {
    .wrapper {
        max-width: 1366px;
    }
}

@media screen and (max-width: 1280px) {
    .wrapper {
        max-width: 1024px;
    }
}

@media screen and (max-width: 1024px) {
    .wrapper {
        max-width: 768px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        max-width: 640px;
    }
}

@media screen and (max-width: 640px) {
    .wrapper {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0px;
        margin-right: 0px;
    }
}

.grid {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(5, 1fr);
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_usually {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
}

button {
    cursor: pointer;
}

.noLink {
    text-decoration: none;
}

.category_block {
    margin-top: 60px;
}

@media screen and (max-width: 1400px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }

}

@media screen and (max-width: 1024px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .App {
        width: 800px;
    }
}

@media screen and (max-width: 778px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .App {
        width: 600px;
    }
}

@media screen and (max-width: 430px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .App {
        width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .App {
        width: 100%;
    }
}

