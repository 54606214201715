.checkbox-wrapper-2 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 10px;
}

.label {
    margin-left: 10px;
}
 .checkbox-wrapper-2 .ikxBAC {
     appearance: none;
     background-color: #dfe1e4;
     border-radius: 72px;
     border-style: none;
     flex-shrink: 0;
     height: 20px;
     margin: 0;
     position: relative;
     width: 30px;
 }

.checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #ad3939;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
    background-color: #ad3939;
}
