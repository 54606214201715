.button {
    width: 150px;
    height: 40px;
    margin-bottom: 30px;
    font-weight: bold;
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid #ad3939;
    color: #a02f12;
}

.button:hover {
    background-color: #a02f12;
    color: #F3F7FF;
}