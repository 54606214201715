.container {
    display: flex;
}

.title {
    font-size: 32px;
    margin-bottom: 20px;
}

.image_block {
    display: flex;
}

.image_block img {
    object-fit: cover;
    display: block;
    border-radius: 20px;
    cursor: pointer;
}

.small_blocks {
    height: 550px;
    overflow-y: scroll;
    padding-right: 5px;
}

/* Стилизация всей полосы прокрутки */
.small_blocks::-webkit-scrollbar {
    width: 4px; /* Ширина вертикальной полосы прокрутки */
}

/* Стилизация трека (фон скроллбара) */
.small_blocks::-webkit-scrollbar-track {
    background: transparent; /* Цвет фона скроллбара */
    border-radius: 10px; /* Закругленные углы */
}

/* Стилизация ползунка (thumb) */
.small_blocks::-webkit-scrollbar-thumb {
    background: #d1d2d5; /* Цвет ползунка */
    border-radius: 10px; /* Закругленные углы ползунка */
}

/* Ползунок при наведении */
.small_blocks::-webkit-scrollbar-thumb:hover {
    background: #c4bfbf;
}

.small_img {
    width: 135px;
    height: 180px;
    transition: .1s;
}

.small_img:hover {
    opacity: 0.7;
}

.small_img + .small_img {
    margin-top: 10px;
}

.big_img {
    width: 420px;
    height: 550px;
    margin-left: 10px;
}

.flex {
    display: flex;
}

.preview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.price {
    color: #a02f12;
    font-size: 28px;
}

.description {
    width: 400px;
    margin-left: 20px;
}

.in_basket {
    width: 170px;
    background-color: transparent;
    border: 2px solid #a02f12;
    border-radius: 10px;
    color: #a02f12;
    font-weight: bold;
    transition: 0.2s;
}

.in_basket_added {
    width: 170px;
    background-color: #eeefef;
    border-radius: 10px;
    border: none;
    color: #c0c1c1;
    font-weight: bold;
}

.in_basket:hover {
    background-color: #a02f12;
    color: white;
}

.text {
    height: 450px;
    overflow-y: scroll;
    font-size: 16px;
    line-height: 1.3;
    padding-right: 15px;
}

/* Стилизация всей полосы прокрутки */
.text::-webkit-scrollbar {
    width: 4px; /* Ширина вертикальной полосы прокрутки */
}

/* Стилизация трека (фон скроллбара) */
.text::-webkit-scrollbar-track {
    background: transparent; /* Цвет фона скроллбара */
    border-radius: 10px; /* Закругленные углы */
}

/* Стилизация ползунка (thumb) */
.text::-webkit-scrollbar-thumb {
    background: #d1d2d5; /* Цвет ползунка */
    border-radius: 10px; /* Закругленные углы ползунка */
}

/* Ползунок при наведении */
.text::-webkit-scrollbar-thumb:hover {
    background: #c4bfbf;
}

.similar_container {
    margin-top: 60px;
}

.similar_products {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 10px;
}

.similar_item {
    width: 180px;
    height: 160px;
    background-color: white;
}

.similar_item_img {
    width: 100%;

}

@media screen and (max-width: 1024px) {
   .container {
       height: 420px;
   }

    .big_img {
        width: 320px;
        height: 400px;
    }

    .small_img {
        width: 90px;
        height: 100px;
    }

    .text {
        height: 350px;
        overflow-y: scroll;
        font-size: 16px;
        line-height: 1.3;
        padding-right: 15px;
    }

    .similar_products {
        margin-top: 20px;
    }

    .similar_item {
        width: 140px;
        height: 130px;
        background-color: white;
    }
}

@media screen and (max-width: 768px) {
    .container {
        height: 490px;
    }

    .image_block {
        display: flex;
        flex-direction: column-reverse;

    }

    .small_blocks {
        display: flex;
        align-items: center;
        margin-top: 7px;
        height: 100%;
    }

    .small_img + .small_img {
        margin-top: 0px;
        margin-left: 5px;
    }

    .big_img {
        width: 270px;
        height: 360px;
        margin: 0;
    }

    .small_img {
        width: 86px;
        height: 110px;
    }

    .text {
        height: 430px;
    }

    .similar_products {
        margin-top: 10px;
    }

    .similar_item {
        width: 130px;
        height: 130px;
        background-color: white;
    }
}

@media screen and (max-width: 425px) {
    .container {
        display: block;
        align-items: center;
    }

    .image_block {
        align-items: center;
    }

    .description {
        width: 97%;
        margin-top: 30px;
        margin-left: 0;
        padding: 5px;
    }

    .text {
        height: 100%;
    }

    .in_basket {
        width: 150px;
        height: 40px;
        margin-right: 10px;
    }

    .big_img {
        width: 360px;
        height: 450px;
    }

    .small_img {
        width: 115px;
        height: 140px;
    }

    .preview {
        width: 370px;
        align-items: center;
    }

    .text {
        font-size: 16px;
        line-height: 1.3;
        padding-right: 0px;
    }

    .similar_container {
        display: none;
    }
}