.wrapper {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapperTitle {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 320px;
}
.title {
    flex: 2;
}
.backButton {
    flex: 1;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
}
.backButton:after {
    content: "←";
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input {
    width: 320px;
    height: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.select {
    width: 340px;
    height: 35px;
}

.button {
    width: 200px;
    height: 30px;
    margin-top: 10px;
    font-weight: bold;
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid #ad3939;
    color: #a02f12;
}

.button:hover {
    background-color: #a02f12;
    color: #F3F7FF;
}