.button {
    width: 150px;
    height: 40px;
    background-color: transparent;
    border: 2px solid #ad3939;
    border-radius: 10px;
    color: #a02f12;
    font-weight: bold;
    transition: .25s;
}

.button:hover {
    background-color: #ad3939;
    color: white;
}

.title {
    margin-bottom: 20px;
    color: #525252;
}

.subtitle {
    margin-bottom: 20px;
    color: #525252;
}

.general_count {
    text-align: right;
    font-size: 18px;
    margin-bottom: 20px;
}