.block {
    position: sticky;
    top: 20px;
    width: 300px;
    height: 200px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #a02f12;
    color: white;
    border-radius: 15px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
}

p + p {
    margin-top: 15px;
}

.button {
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 9px;
    font-weight: bold;
    width: 100%;
    height: 40px;
}

.button:hover {
    background-color: white;
    color: #ad3939;
}

.button_link {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    height: 40px;
}


@media screen and (max-width: 1920px) {
    .block {
        width: 300px;
        height: 200px;
    }

}


@media screen and (max-width: 1440px) {
    .block {
        width: 300px;
        height: 200px;
    }

}

@media screen and (max-width: 1250px) {
    .block {
        width: 300px;
        height: 200px;
    }

}

@media screen and (max-width: 1024px) {
    .block {
        width: 740px;
        height: 200px;
        padding: 15px;
        background-color: #a02f12;
        color: white;
        border-radius: 15px;
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 768px) {
    .block {
        position: static;
        width: 500px;
        height: 200px;
        padding: 15px;
        background-color: #a02f12;
        color: white;
        border-radius: 15px;
        margin-bottom: 30px;
    }
}


@media screen and (max-width: 385px) {
    .block {
        position: static;
        width: 330px;
        height: 200px;
        padding: 15px;
        background-color: #a02f12;
        color: white;
        border-radius: 15px;
        margin-bottom: 30px;
    }

}