.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paragraph {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    line-height: 60px;
}

.video {
    border-radius: 20px;
}

.button {
    width: 140px;
    height: 40px;
    margin-left: 20px;
    margin-top: 50px;
    font-weight: bold;
    background-color: transparent;
    border: 2px solid #ad3939;
    border-radius: 10px;
    color: #a02f12;
    transition: .25s;
}

.button:hover {
    background-color: #a02f12;
    color: #F3F7FF;
}

@media screen and (max-width: 400px) {
    .paragraph {
        font-size: 24px;

    }

}
