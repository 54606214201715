.header {
    width: 100%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.basket {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.log {
    width: 35px;
    height: 35px;
    cursor: pointer;
}

.logo_name {
    font-size: 40px;
    font-weight: bold;
    color: #a02f12;
}

.link{
    margin-right: 20px;
}

