.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 30px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
}

.block:hover {
    background-color: #d1d2d5;
}

.block + .block {
    margin-top: 10px;
}


@media screen and (max-width: 400px) {
    .block {
        font-size: 14px;
        width: 93%;
    }
}
