.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
/*    width: 175px;
    height: 270px;*/
    box-shadow: 0px 4px 30px 0px #094B9D1A;
    border-radius: 20px;
    padding: 15px;
    background-color: white;
}

.imageContainer {
    position: relative;
    width: 180px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 7px;
}

.img {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
}

.name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.price {
    font-size: 16px;
    margin-bottom: 10px;
}

.button {
    width: 100%;
    height: 30px;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #ad3939;
    color: #a02f12;
    font-weight: bold;
}

.button_added {
    width: 100%;
    height: 30px;
    background-color: #eeefef;
    border-radius: 10px;
    border: none;
    color: #c0c1c1;
    font-weight: bold;
}

.link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

@media screen and (max-width: 375px) {
    .imageContainer {
        width: 170px;
    }

    .card {
        width: 145px;
    }
}

